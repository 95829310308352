<template>

  <div>

    <!-- <subscription-list-add-new :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions" :plan-options="planOptions" @refetch-data="refetchData" /> -->

    <!-- Filters -->
    <!-- <subscriptions-list-filters :role-filter.sync="roleFilter" :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter" :role-options="roleOptions" :plan-options="planOptions"
      :status-options="statusOptions" /> -->

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select v-model="options.perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>entries</label>
          </b-col>


        </b-row>

      </div>



      <b-table ref="refSubscriptionListTable" primary-key="id" :items="fetchSubscription" :fields="tableColumns"
        :sort-by.sync="options.sortBy" :sort-desc.sync="options.sortDesc" :current-page.sync="options.currentPage"
        per-page="0" :filter="null" :api-url="null" show-empty class="position-relative" responsive
        empty-text="No matching records found" sticky-header="70vh" hover :busy="busy" head-variant="light"
        no-sort-reset>

        <!-- Column: Subscription -->
        <template #cell(User)="data">
          <div class="text-nowrap">
            <!-- <feather-icon size="18" class="mr-50" :class="`text-primary`" /> -->
            <span class="align-text-top text-capitalize"> {{ (data.item.user != null ? data.item.user.name : '-')
            }}</span>
          </div>
        </template>

        <!-- Column: Role -->
        <template #cell(Plan)="data">
          <div class="text-nowrap">
            <!-- <feather-icon size="18" class="mr-50" :class="`text-primary`" /> -->
            <b-badge pill :variant="`light-primary`" class="text-capitalize">
              {{ (data.item.product != null ? data.item.product.name : '-')
              }}
            </b-badge>

          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(startDate)="data">
          <b-badge pill :variant="`light-primary`" class="text-capitalize">
            {{ format_date(data.item.current_period_start) }}
          </b-badge>
        </template>

        <template #cell(endDate)="data">
          <b-badge pill :variant="`light-primary`" class="text-capitalize">
            {{ format_date(data.item.current_period_end) }}
          </b-badge>
        </template>

        <template #cell(status)="data">
          <b-badge pill :variant="`light-primary`" class="text-capitalize">
            {{ data.item.status }}
          </b-badge>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ (options.currentPage - 1) * options.perPage }} to {{
                (options.currentPage - 1) * options.perPage + options.perPage
            }} of
              {{ totalListings }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="options.currentPage" :per-page="options.perPage" :total-rows="totalListings"
              first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import SubscriptionsListFilters from './SubscriptionsListFilters.vue'
import SubscriptionListAddNew from './SubscriptionListAddNew.vue'
import moment from 'moment'

export default {
  components: {
    SubscriptionsListFilters,
    SubscriptionListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  mounted() {
    this.fetchSubscriptions()
  },
  data() {
    return {
      totalListings: 0,
      options: {
        currentPage: 1,
        perPage: 50,
        sortBy: "id",
        sortDesc: true,
        // filter: null,
        // apiUrl: null,
      },
      perPageOptions: [10, 50, 100, 150, 200, 250],
      fetchSubscription: [],
      tableColumns: [
        { key: 'User', sortable: true },
        { key: 'Plan', sortable: true },
        {
          key: 'startDate',
          label: 'Start Date',
          sortable: true,
        },
        {
          key: 'endDate',
          label: 'End Date',
          sortable: true,
        },
        { key: 'status', sortable: true },

      ],
      busy: false,
    }
  },
  watch: {
    options: {
      deep: true,
      handler() {
        this.fetchSubscriptions();
      },
    },
  },
  methods: {
    fetchSubscriptions() {
      this.busy = true;
      this.$axios
        .post(`${this.$API_URL_SUBSCRIPTIONS}/fetchSubscriptions`, { filters: this.filters, options: this.options })
        .then((subscriptions) => {
          this.fetchSubscription = subscriptions.data.response;
          // this.totalListings = subscriptions.data.response.count
          // this.busy = false;
        })
        .catch(error => console.log(error))
    },
    deleteSubscription(id) {
      console.log(id)
    },
    format_date(value) {
      if (value) {
        console.log(value);
        return moment.unix(value).format('YYYY-MM-DD')
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
